import { Box, FormControl, 
  Select,MenuItem,InputLabel,List,ListItem, ListItemText,Typography,  Alert, Snackbar,
   useTheme,Button,TextField,FormControlLabel,Checkbox} from "@mui/material";
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import Mony from'@mui/icons-material/SaveAsRounded';
import { AuthContext } from "../../context/AuthContext";
import React, { useEffect, useState, useContext,useCallback} from "react";
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import {Formik, useFormik } from 'formik';

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx2={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const style2 = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 
  
};
const stylePrint = {
  body: {
    '*': {
      visibility: 'hidden',
    },
  },
  '#printable-content': {
    visibility: 'visible',
    position: 'absolute',
    left: 0,
    top: 0,
  },
};

const style = { 
	position: "absolute", 
	top: "50%", 
	left: "60%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 350, 
  borderColor: "#d50000", 
	p: 4, 
}; 
const Agents = () => {
const theme = useTheme();



// const [open,setOpen]=React.useState(false);
const [opent, setOpent] = React.useState(false);
const handleOpent = () => setOpent(true);
const handleCloseregt = () => setOpent(false);

const [openDetailTransaction,setopenDetailTransaction]=React.useState(false)
const closeOpenTransaction =()=>setopenDetailTransaction(false)

const [openApproval,setOpenApproval]=React.useState(false)
const handleopenApproval =()=>setOpenApproval(true)
const closeApproval=()=>setOpenApproval(false)



const colors = tokens(theme.palette.mode);
const navigate = useNavigate()

const isNonMobile = useMediaQuery("(min-width:600px)");
const { currentUser,url,logout } = useContext(AuthContext);
// const [userreg, setValues] = useState([]);
const [value, setValue] = React.useState(0);
const [notification, setNotification] = useState(null);
const [isRedirecting, setIsRedirecting] = useState(false);
const [totalBalance, setTotalAgentBalance] = useState(false);


// Transaction
const [values, setValues] = useState({
  transactionmethod: "",
  username:currentUser.username,
  amaunt:"" 
})

//Depost for Agent

function handleDepositAgent(e){
  e.preventDefault()
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };
  axios.post(url+`/api/emptransac/agent/information/deposit/${agID}`, values,config)
  .then((res)=>{
    setOpent(false);
      if(res.data=="The transaction success"){
        // alert("Request Success")
        setNotification({
          show: true,
          type: 'success',
          message: "Transaction success! "
      });
     }
     else{
       setNotification({
        show: true,
        type: 'error',
        message:res.data
    });
     }
     setIsRedirecting(true);

     const timer = setTimeout(() => {
         window.location.reload();

        }, 1000);
   
     return () => clearTimeout(timer);
  })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }

};
}

//end Deposite for Agent

// Approval
const [approvalID, setapprovalID]=useState('')

function handleOpenApprovalModal(id){
  setapprovalID(id);
  setOpenApproval(true)

}
const expotokenTooken=(exp)=>{
  alert(exp)
}

 const handlePinreset = (agentID) => { 
  
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };
  
  axios.post(url+`/api/emptransac/agent/pinreset/${agentID}`,{username:currentUser.username},config)
    .then((res)=>{
     setNotification({
      show: true,
      type: 'info',
      message: res.data
  });
  setIsRedirecting(true);
  
       const timer = setTimeout(() => {
  window.location.reload();
       }, 3000);
          return () => clearTimeout(timer);
         })
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
  
  };

}

// const [status, setApproval] = useState({
//   status: "",
//   // username: currentUser.username || "" // Ensure currentUser.username is defined
// });

const [statusAll, setApproval] = useState({

  username:currentUser.username,
  statusApp:"" 
})
function handleapproval(id){
  // alert(statusAll.username)
try {
  const config = {
      headers: {
          'Authorization': `Bearer ${currentUser.accessToken}`
      }
  };

axios.put(url+`/api/emptransac/agent/approval/${id}`, statusAll,config)
  .then((res)=>{
   setNotification({
    show: true,
    type: 'info',
    message: res.data
});
setIsRedirecting(true);

     const timer = setTimeout(() => {
window.location.reload();
     }, 3000);
        return () => clearTimeout(timer);
       })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }

};
}

//End Approval


//start register agent
const [openAgent, setOpenAgent] = React.useState(false); 
  const handleOpenAgent = () => setOpenAgent(true);

const handleCloseAgent = () => setOpenAgent(false);

const formikAgent = useFormik({
  initialValues: {
    agentName: "",
    agentRepFirstName: "",
    agentRepMiddleName: "",
    agentRepLastName: "",
    agentTIN: "",
    phoneNumber:"",
    pin:"",
    email:"",
    approvedBy:"",
  username:currentUser.username,
  }, onSubmit: (values) => {
        try {
          const config = {
              headers: {
                  'Authorization': `Bearer ${currentUser.accessToken}`
              }
          };
    axios.post(url+"/auth/agent/register/byusers", values,config).then((res) => {
      // userReg(userReg.concat(res.data));
      // alert(res.data)
      setOpent(false);
      setNotification({
        show: true,
        type: 'success',
        message: res.data 
    });
    setIsRedirecting(true);
    const timer = setTimeout(() => {
      window.location.reload();
 
       }, 2000);
  
    return () => clearTimeout(timer);
    });
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
}
  },    

  });


//end Agent Registeration
  const [recieved, setRecieved] = useState({
  
    username:currentUser.username,
   // recievedstatus:"",
  })
  function handleRecieved(id){
  axios.post(url+`/api/emptransac/recievedcash/${id}`, recieved)
  
    .then((res)=>{
    })
    .catch((err)=>console.log(err))
  }
  const [agID, setAgId] = useState([]);
  const [agentTIN, setAgentTIN] = useState([]);
  const [agentName, setAgentName] = useState([]);
  const [balance, setBalanceAgent] = useState(null);
  const [bonusAgent, setBonus] = useState(null); 
  function handleDeposit(id,agentTIN,agentName,balance,bonus){

    setAgId(id);
    setAgentTIN(agentTIN);
    setAgentName(agentName);
    setBalanceAgent(balance);
    setBonus(bonus);
    handleOpent(true)
  }
  const [tx, setTX] = useState([]);
  const [pendAgentTransaction, setpendAgentTransaction] = useState([]);
  const [agent, setAgentInformation] = useState([]);
  const [agentTransaction, setagentTransaction] = useState([]);
  const emptranfetch = useCallback(async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${currentUser.accessToken}`
        }
      };
      const response = await axios.post(url + '/api/emptransac/agent/information/fetch', { username: currentUser.username }, config);
      setAgentInformation(response.data.agent);
      setagentTransaction(response.data.transactionList);
      setpendAgentTransaction(response.data.pendAgentTransaction);
      setTotalAgentBalance(response.data.agentBalance)
    } catch (e) {
      if (e.response && e.response.status === 401) {
        console.log('No valid token or session expired');
        logout();
      } else {
        console.error('Error fetching agent information:', e);
      }
    }
  }, [currentUser.username, currentUser.accessToken, url, logout]);

  useEffect(() => {
    emptranfetch();
  }, [emptranfetch]);
const [agentid,setagentid]=useState(0)
const [agentNamedisp,setagentName]=useState(0)
const [agentRepFirstNamedisp,setagentRepFirstName]=useState(0)
const [agentRepMiddleNamedisp,setagentRepMiddleName]=useState(0)
const [agentRepLastNamedisp,setagentRepLastName]=useState(0)
const [agentTINdisp,setagentTIN]=useState(0)
const [phoneNumberdisp,setphoneNumber]=useState(0)
const [otp,setotp]=useState(0)
const [expotoken,setexpotoken]=useState(0)
const [usernameagent,setusername]=useState(0)
const [address,setaddress]=useState(0)
const [emailagent,setemail]=useState(0)
const [balanceagent,setbalance]=useState(0)
const [commission,setcommission]=useState(0)
const [bonus,setbonus]=useState(0)
const [statusagent,setstatus]=useState(0)
const [registeredBy,setregisteredBy]=useState(0)
const [approvedBy,setapprovedBy]=useState(0)
const [createdAtagent,setcreatedAt]=useState(0)
const[updatedAtagent,setupdatedAt]=useState(0)

function formatDate(date) {
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error('Invalid date');
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}/${month}/${day}`;
}


function handleopenDetailTransactionDisp(agentid ,agentName,agentRepFirstName,
  agentRepMiddleName,agentRepLastName,agentTIN, phoneNumber, otp,expotoken, 
  username2,address2,email,balance,commission,bonus,status,registeredBy,
  approvedBy,createdAt2,updatedAt2){
  setagentid(agentid)
  setagentName(agentName)
  setagentRepFirstName(agentRepFirstName)
  setagentRepMiddleName(agentRepMiddleName)
  setagentRepLastName(agentRepLastName)
  setagentTIN(agentTIN)
  setphoneNumber(phoneNumber)
  setotp(otp)
  setexpotoken(expotoken)
  setusername(username2)
  setaddress(address2)
  setemail(email)
  setbalance(balance)
  setcommission(commission)
  setbonus(bonus)
  setstatus(status)
  setregisteredBy(registeredBy)
  setapprovedBy(approvedBy)
  setcreatedAt(createdAt2)
  setupdatedAt(updatedAt2)
  setopenDetailTransaction(true)
}
  const columns = [
    {
      field: "id",
      headerName: "No",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "agentName",
      headerName: "Agent Name",
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "agentRepFirstName",
      headerName: "Name",
      headerAlign: "left",
      align: "left",
      flex: 2,
    },
    {
      field: "agentTIN",
      headerName: "agentTIN",
      flex: 2,
    },
    {
      field: "balance",
      headerName: "balance", filter:true,
      flex: 1,
    },
    {
      field: "commission",
      headerName: "commission", filter:true,
      flex: 1,
    },
    {
      field: "status",
      headerName: "status",
      flex: 1,
    },

    {
      headerName: "actions",
      flex: 1,
      renderCell: ({ row: { id ,agentTIN,agentName,balance,bonus,status} }) => {
        return (
          status=="Pending"?
          <Box
            m="0 auto"
            backgroundColor={
                colors.redAccent[600]
            }
            borderRadius="4px"
          >
             <Typography>
                <TextField select name="statusApp" 
                  required
                  onChange={(e)=> setApproval({...statusAll, statusApp: e.target.value})}    
                  placeholder='waliif'>
                  <MenuItem value="active">
                  Approved
                </MenuItem>
                <MenuItem value="Reject">
                  Reject
                </MenuItem>
              </TextField>
             </Typography>
            </Box>:status=="Reject"?null:
            status=="active"?
              <Box
                    m="0 auto"
                    backgroundColor={
                        colors.blueAccent[600]
                    }
                    borderRadius="4px"
                  >
                    <Button type="submit" onClick={ () => {handleDeposit(`${id}`,`${agentTIN}`,`${agentName}`,`${balance}`,`${bonus}`)} }
                      sx={{
                        fontSize: '8px',
                        padding: '6px 10px',
                      }}
                    >
                      Deposit
                    </Button>
                  </Box>:null
        );    
      }
    },
    {
      field: "",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id ,agentName,agentRepFirstName,agentRepMiddleName,agentRepLastName,agentTIN,
        phoneNumber,pin,otp,expotoken, username,address,email,balance,commission,bonus,status,registeredBy,
        approvedBy,createdAt,updatedAt} }) => {
        return (
          status==="Pending"?
          <Box
            m="0 auto"
            backgroundColor={
                colors.redAccent[600]
            }
            borderRadius="4px"
          >
                   <Button type="submit" onClick={ () => {handleapproval(`${id}`)} }
              sx={{
                fontSize: '8px',
                padding: '6px 10px',
              }}
            >
              Apply 
            </Button>
          </Box>
          : status=="Reject"?
          <Box
          m="0 auto"
          backgroundColor={
              colors.redAccent[600]
          }
          borderRadius="4px"
        >
          <Button type="submit" onClick={ () => {handleopenDetailTransactionDisp(`${id}`,`${agentName}`,`${agentRepFirstName}`,
          `${agentRepMiddleName}`,`${agentRepLastName}`,`${agentTIN}`,`${phoneNumber}`,`${otp}`,`${expotoken}`,
          `${username}`,`${address}`,`${email}`,`${balance}`,`${commission}`,
          `${bonus}`,`${status}`,`${registeredBy}`,`${approvedBy}`,`${createdAt}`,`${updatedAt}`)} }
            sx={{
              fontSize: '8px',
              padding: '6px 10px',
            }}
            >
            detail
          </Button>
        </Box>:status=="active"?
          <Box
          m="0 auto"
          backgroundColor={
              colors.blueAccent[600]
          }
          borderRadius="4px"
        >
          <Button type="submit" onClick={ () => {handleopenDetailTransactionDisp(`${id}`,`${agentName}`,
          `${agentRepFirstName}`,
          `${agentRepMiddleName}`,`${agentRepLastName}`,`${agentTIN}`,`${phoneNumber}`,`${otp}`,
          `${expotoken}`,`${username}`,`${address}`,
        
          `${email}`,`${balance}`,`${commission}`,`${bonus}`,`${status}`,`${registeredBy}`,
          `${approvedBy}`,`${createdAt}`,`${updatedAt}`
          ,`${registeredBy}`)} }
            sx={{
              fontSize: '8px',
              padding: '6px 10px',
            }}
            >
            detail
          </Button>
        </Box>:null
        );
      },
    },

     ];
     //start
     const column = [
      {
        field: "id",
        headerName: "ID",
        flex: 1,
        cellClassName: "name-column--cell",
      },
      {
        field: "agentId",
        headerName: "agentId",
        flex: 1,
        cellClassName: "name-column--cell",
      },
      {
        field: "previoustotal",
        headerName: "previoustotal",
        flex: 1,

      },
      {
        field: "handler",
        headerName: "handler",
        flex: 2,
      },
      {
        field: "creditaccount",
        headerName: "creditaccount",
        flex: 2,
      },
      {
        field: "debitaccount",
        headerName: "debitaccount",
        flex: 2,
      },
      
      {
        field: "amount",
        headerName: "amount",
        flex: 1,
      },
      {
        field: "newtotal",
        headerName: "total",
        flex: 1,
      },
      {
        field: "status",
        headerName: "status", filter:true,
        flex: 1,
      },
      {
        field: "createdAt",
        headerName: "createdAt",
        flex: 2,
      },
      {
        field: "updatedAt",
        headerName: "updatedAt",
        flex: 2,
      },
      {
        field: "Edit",
        headerName: "Detail",
        flex: 1,
        renderCell: ({ row: {id } }) => {
          return (
            <Box
              m="0 auto"
              backgroundColor={
                  colors.blueAccent[600]
              }
              borderRadius="4px"
            >
              <Button type="submit" onClick={ () => {handleRecieved(`${id}`)} }
              sx={{
                fontSize: '8px',
                padding: '6px 10px',
              }}
              >
                Detail
               
              </Button>
            </Box>
           
          );
        },
      },
       ];
       const columnT = [
        {
          field: "id",
          headerName: "ID",
          flex: 1,
          cellClassName: "name-column--cell",
        },
        {
          field: "agentId",
          headerName: "agentId",
          flex: 1,
          cellClassName: "name-column--cell",
        },
        {
          field: "transactionmethod",
          headerName: "transactionmethod",
          flex: 1,
        },
        {
          field: "creditaccount",
          headerName: "creditaccount",
          flex: 1,
        },
        {
          field: "debitaccount",
          headerName: "debitaccount",
          flex: 1,
        },
 
        {
          field: "amaunt",
          headerName: "amaunt",
          flex: 1,
        },
        {
          field: "status",
          headerName: "status", filter:true,
          flex: 1,
        },
        {
          field: "createdAt",
          headerName: "createdAt",
          flex: 1,
        },
        {
          field: "reciept",
          headerName: "Reciept photo",
          flex: 1,
          renderCell: ({ row: {attachement} }) => {
            return (
              <Typography>
                 <img src={url+attachement} width={295} height={365} alt=""/>

              </Typography>
            )}
        },
        {
          field: "updatedAt",
          headerName: "Action",
          flex: 1,
          renderCell: ({ row: {id} }) => {
            return (
              <Typography>
                <TextField select name="statusApp" 
                required
                  onChange={(e)=> setApproval({...statusAll, statusApp: e.target.value})}    
                  placeholder='waliif'>
                  <MenuItem value="Approved">
                  Approved
                </MenuItem>
                <MenuItem value="Reject">
                  Reject
                </MenuItem>
              </TextField>
             </Typography>
            );
          }
        },
        {
          field: "accessLevel",
          headerName: "Detail",
          flex: 1,
          renderCell: ({ row: { id } }) => {
            return (
              <Box

                backgroundColor={
                    colors.blueAccent[100]
                }
              >
                <Button type="submit" onClick={ () => {handleapproval(`${id}`)} }   sx={{
                fontSize: '8px',
                padding: '4px 10px',
              }}>
                 Apply
                </Button>
              </Box>
            );
          },
        },
         ];
     const handleChange = (event, newValue) => {
      setValue(newValue);
       };
  return (
    <Box m="20px">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
		      backgroundColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full"
		      display="flex"
        >
      <Tab label="Agents List" {...a11yProps(0)} />
      <Tab label="Agents Transactions" {...a11yProps(1)} />
       <Tab label="Manage Agents REQUEST" {...a11yProps(2)} /> 
		    </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
      <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          height: '40px', 
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '14px', 
            padding: '0 16px',
          },
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
          height: '40px', 
          '& .MuiDataGrid-footer': {
            fontSize: '14px', 
            padding: '0 16px', 
          },
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
        <Box
            backgroundColor={
                colors.blueAccent[600]
            }
          >
        <Button aria-describedby={openAgent} onClick={handleOpenAgent}
          sx={{
            fontSize: '8px',
            padding: '6px 10px',
          }}
       > 
					Add Agent
				</Button>
        </Box>



    <Modal 
      
      open={openDetailTransaction} 
      onClose={closeOpenTransaction} 
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      
    > 
       <>
      <style media="print">{`
        ${Object.entries(stylePrint).map(([key, value]) => `${key} {${Object.entries(value).map(([k, v]) => `${k}: ${v};`).join('')}}`).join('')}
      `}</style>
    <Box sx={style2  } 
      backgroundColor={colors.grey[400]}
      display="flex"
      gridRow="span 3" 
      marginRight={50}
       > 
          <Box  justifyContent="center">
          <Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
          <table border="1"     id="printable-content"> 
            <tr background-color= "#f2f2f2">
                <td>Attribute</td>
                <td>Value</td>
                <td>Attribute</td>
                <td>Value</td>

            </tr>
            <tr>
                <td>Agent ID</td>
                <td>{agentid}</td>
                <td>Bussiness Name</td>
                <td>{agentNamedisp}</td>

            </tr>
            <tr>
            <td> F.Name</td>
                <td>{agentRepFirstNamedisp}</td>
                <td>M.Name</td>
                <td>{agentRepMiddleNamedisp}</td>
                </tr><tr>
                <td>L.Name</td>
                <td>{agentRepLastNamedisp}</td>
                <td>TIN</td>
                <td>{agentTINdisp}</td>
            </tr>
            <tr>
            <td>Tel</td>
                <td>{phoneNumberdisp}</td>
                <td>OTP</td>
                <td>{otp}</td>

 
            </tr>
          <tr>
          <td>Expo</td>
                <td>
                <Button variant="secondary" color="info" onClick={() => expotokenTooken(expotoken)} position="right">
  Expo-Token
</Button>
                  </td>
            <td>username</td>
                <td>{usernameagent}</td>
                </tr>
                <tr>
                <td>Address</td>
                <td>{address}</td>
                <td>email</td>
                <td>{emailagent}</td>
                               
            </tr>
            <tr>
            <td>balance</td>
                <td>{balanceagent}</td>
                <td>commission</td>
                <td>{commission}</td>

            </tr>
            <tr>
            <td>bonus</td>
                <td>{bonus}</td>
            <td>Status</td>
                <td>{statusagent}</td>
                </tr><tr>
                <td>registeredBy</td>
                <td>{registeredBy}</td>
                <td>approvedBy</td>
                <td>{approvedBy}</td>
            </tr>
            <tr>
                <td>Created Date</td>
                <td>{createdAtagent}</td>
                <td>Updated Date</td>
                <td>{updatedAtagent}</td>

            </tr>
         </table>
         <Box>
         <Button variant="secondary" color="info" onClick={() => handlePinreset(agentid)} position="right">
  PIN RESET
</Button>
    <Button variant="secondary" color="error"onClick={closeOpenTransaction} position="right">
  Close
</Button>
<Button variant="secondary" color="error" onClick={() => window.print()}>
Print
</Button>
</Box>
        </Typography>
            
      </Box>

    </Box>
    </>
   </Modal>


   <Modal 
 
 open={openAgent} 
 onClose={handleCloseAgent} 
 aria-labelledby="modal-modal-title"
 aria-describedby="modal-modal-description"
> 
<Box
backgroundColor={colors.grey[400]}

sx={style}
>
<Typography id="modal-modal-description"
 > 
<Formik
 initialValues={{agentName: "", agentRepFirstName: "",agentRepMiddleName:"",agentRepLastName:"", agentTIN:"",phoneNumber:"",pin:"",email:"", approvedBy:""}}
 onSubmit={formikAgent.onSubmit}
>
 <form className='formContainer' onSubmit={formikAgent.handleSubmit} encType='multipart/form-data'>
         <ListItem  >

           <TextField  
             label="Agent Bussiness Name"
             required
               sx={{
                 '& .MuiInputBase-root': {
                   height: 40, 
                 },
               }}
               InputProps={{
                 style: {
                   padding: '0 14px', 
                 },
               }}                         
                onChange={formikAgent.handleChange}
               value={formikAgent.values.agentName}
               name="agentName"
               size="small"
             />
   </ListItem><ListItem>
           <TextField
               sx={{
                 '& .MuiInputBase-root': {
                   height: 40, 
                 },
               }}
               InputProps={{
                 style: {
                   padding: '0 14px', 
                 },
               }}                          
               type="text"
               label="agentRepFirstName"
               onChange={formikAgent.handleChange}
               value={formikAgent.values.agentRepFirstName}
               name="agentRepFirstName"
               
               />
         </ListItem>
         <ListItem>
           <TextField
           sx={{
             '& .MuiInputBase-root': {
               height: 40, 
             },
           }}
           InputProps={{
             style: {
               padding: '0 14px', 
             },
           }}                          
           type="text"
               label="agentRepMiddleName"
               onChange={formikAgent.handleChange}
               value={formikAgent.values.agentRepMiddleName}
               required
               name="agentRepMiddleName"
              
               >
           </TextField>
           </ListItem>
         <ListItem>
               <TextField
               
               type="text"
               label="agentRepLastName"
               onChange={formikAgent.handleChange}
               value={formikAgent.values.agentRepLastName}
               required
               name="agentRepLastName"
               sx={{
                 '& .MuiInputBase-root': {
                   height: 40, 
                 },
               }}
               InputProps={{
                 style: {
                   padding: '0 14px', 
                 },
               }}
               ></TextField>
           </ListItem>
           <ListItem>
              <TextField
               type="text"
               label="phoneNumber"
               size="small"
               pattern="[1-9]*"
               inputProps={{
                 maxLength: 9,
                 minWidth:9
               }}
               InputProps={{
                 disableUnderline: true,
                 style: {
                   padding: '0 14px', 
                 },
               }}
               
               onChange={formikAgent.handleChange}
               value={formikAgent.values.phoneNumber}
               required
               name="phoneNumber"
               sx={{
                 '& .MuiInputBase-root': {
                   height: 40, 
                 },
               }}
             
               ></TextField>
             </ListItem>
          <ListItem>
              <TextField
                 sx={{
                   '& .MuiInputBase-root': {
                     height: 40, 
                   },
                 }}
                 InputProps={{
                  
                 maxLength: 6,
                 minWidth:6,
                   style: {
                     padding: '0 14px', 
                   },
                 }}
               type="text"
               label="agentTIN"
               pattern="[1-9]*"
              
        
               onChange={formikAgent.handleChange}
               value={formikAgent.values.agentTIN}
               required
               name="agentTIN"
               ></TextField>
             </ListItem>
             <ListItem>
             <TextField
               label="PIN"
               type="password"
               onChange={formikAgent.handleChange}
               value={formikAgent.values.pin}
               required
               name="pin"
               sx={{
                 '& .MuiInputBase-root': {
                   height: 40, 
                 },
               }}
               InputProps={{
                 style: {
                   padding: '0 14px', 
                 },
               }} 
              
               ></TextField>
           </ListItem>
           <ListItem>
             <TextField
               label="email"
               type="email"
               onChange={formikAgent.handleChange}
               value={formikAgent.values.email}
               required
               name="email"
               sx={{
                 '& .MuiInputBase-root': {
                   height: 40, 
                 },
               }}
               InputProps={{
                 style: {
                   padding: '0 14px', 
                 },
               }} 
               ></TextField>
           </ListItem>
           <ListItem>
             <TextField
               label="approvedBy"
               type="text"
               onChange={formikAgent.handleChange}
               value={formikAgent.values.approvedBy}
               required
               name="approvedBy"
               sx={{
                 '& .MuiInputBase-root': {
                   height: 40, 
                 },
               }}
               InputProps={{
                 style: {
                   padding: '0 14px', 
                 },
               }} 
               ></TextField>
           </ListItem>
           <FormControlLabel
               control={
                     <Checkbox
                     onChange={formikAgent.handleChange}
                     value={formikAgent.values.email}
                       inputProps={{ 'aria-label': 'controlled' }}
                     />
                   }
                   label="I have agreed to work in accordance with the company agent's rules and regulations."
                 />
             <Button type="submit" color="secondary" variant="contained" onClick={formikAgent.onSubmit}>
              Register Agent
             </Button>&nbsp; 
             <Button   onClick={handleCloseAgent} position="left" variant="contained"color="warning">
               Close
             </Button>  
   </form>
 </Formik>
</Typography> 
</Box> 
</Modal> 
<Modal   
      open={opent} 
      onClose={handleCloseregt} 
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    > 
      <Box sx={style2  } 
                backgroundColor={colors.grey[400]}
                gridRow="span 2" 
                marginRight={50}
                width={10}
      > 
              <Box
            backgroundColor={
                colors.blueAccent[600]
            }
          >
        <Typography 
          > 
          Deposit for Agent Format, Before doing, make sure the Agent Information is correct
        </Typography> 
        </Box>
        <table>
          <tr>
          <td>  Name</td><td>:{agentName}</td>
          <td>  TIN</td><td>{agentTIN}</td>
          </tr><tr>
          <td>  Contact</td><td>{balance}</td>
          <td>  Balance</td><td>{balance}</td>
          <td>  Bonus</td><td>{bonusAgent}</td>
         </tr>
         </table>
        <Typography id="modal-modal-description"
          sx={{ mt: 2 }}> 
          <form onSubmit={handleDepositAgent}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
>               <TextField
              variant="filled"
              type="number"
              label="amaunt"
              style = {{width: 200}} 
              onChange={(e)=> setValues({...values, amaunt: e.target.value})}
              required
              columns={2}
              name="amaunt"
              ></TextField>
             </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
            Apply
            </Button> &nbsp;&nbsp;
            <Button variant="secondary" onClick={handleCloseregt} position="right">
          Close
        </Button>
          </Box>
          </form>
        </Typography> 
      </Box> 
    </Modal>
          {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}    
                  <DataGrid
                  style={{ height: '100%', width: '100%' }}
                  rows={agent}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  rowHeight={20} // Set the desired row height here
                /> 
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
              <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.greenAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
                }}
              >
          
      <DataGrid style={{height: '100%', width: '100%'}} rows={agentTransaction} columns={column} 
       components={{ Toolbar: GridToolbar }} rowHeight={20}/>
    </Box>
    </TabPanel>
    <TabPanel value={value} index={2} dir={theme.direction}>
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          height: '40px', // Set the desired header height here
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '14px', // Adjust font size
            padding: '0 16px', // Adjust padding
          },
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          // backgroundColor: colors.blueAccent[700],
          height: '40px', // Set the desired footer height here
          '& .MuiDataGrid-footer': {
            fontSize: '8px', // Adjust font size
            padding: '0 10px', // Adjust padding
          },
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
       {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )} 
         <DataGrid style={{height: '100%', width: '100%'}} rows={pendAgentTransaction} columns={columnT} 
          components={{ Toolbar: GridToolbar }} rowHeight={20} />
      </Box>
      </TabPanel>
  </Box>
);

};

export default Agents;
